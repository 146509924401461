<template>
  <div class="video-page">
    <div class="wrap">
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/list' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="video-con z-flex">
        <div class="video-box">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnded($event)"
                        @waiting="onPlayerWaiting($event)"
                        @playing="onPlayerPlaying($event)"
                        @canplay="onPlayerCanplay($event)"
          >
          </video-player>

<!--          <video class="video" :src="activeVideoUrl" poster="" controls="controls">-->
<!--            您的浏览器不支持 video 标签。-->
<!--          </video>-->
          <div class="tips-box z-flex z-flex-col z-flex-center" v-if="dialogBuyTips">
            <div class="text">您还没有购买本节课程哦，购买后开通观看权限！</div>
            <div class="btn-group">
              <el-button @click="toBuyVideo">购买当前视频</el-button>
              <el-button type="primary" @click="toBuyCourse">购买本节课程</el-button>
            </div>
          </div>
        </div>
        <div class="catelog-list">
          <div :class="['item',activeVideoId==item.id ? 'active':'']" v-for="(item,index) in courseCatelog" :key="index" @click="toVideo(item)">
            <i class="el-icon-caret-right"></i>
            <span class="title">{{item.title}}</span>
          </div>
        </div>
      </div>
      <div class="main-con z-flex z-flex-between">
        <div class="main-con-l">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="课程目录" name="courseCatelog">
              <div class="catelog-list" v-if="courseCatelog.length>0">
                <div :class="['item', 'z-flex',activeVideoId==item.id ? 'active':'']" v-for="(item,index) in courseCatelog" :key="index" @click="toVideo(item)">
                  <i class="icon-play el-icon-video-play"></i>
                  <span class="title">{{item.title}}</span>
                  <el-tag class="attach" effect="dark" size="mini" v-if="item.attach" @click.stop="downloadThisAttach">本节课附件下载</el-tag>
                  <span class="duration">{{item.duration}}</span>
                </div>
              </div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane label="课程介绍" name="courseIntro">
              <div class="courseIntro-con" v-if="courseIntro" v-html="courseIntro"></div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <!-- <el-tab-pane label="讨论区" name="comments">
              <div class="comments-con" v-if="comments"></div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane> -->
          </el-tabs>
        </div>
        <!-- 相关推荐 -->
        <recommend></recommend>
      </div>
    </div>
  </div>
</template>

<script>
import recommend from "../components/recommend"
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  components: {
    recommend,
    videoPlayer
  },
  data() {
    return {
      title:'后期秘籍',//课程
      course: null,
      video: null,
      activeVideoId:0,//当前视频id
      chapters: null,
      courseCatelog:[], //课程目录
      courseIntro:'',//课程介绍
      comments:'',//评论
      activeTab:'courseCatelog',
      isWatch: false,//是否可以观看
      isBuy: false,//是否购买
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: 'https://dyyxclub.oss-cn-hangzhou.aliyuncs.com/images/grTxWErKrA2t308qPgSayM3vlBZ0bLnokPZvSiJG.bmp', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      dialogBuyTips:false,
    };
  },
  computed: {

  },
  mounted() {
    this.activeVideoId = this.$route.params.id;
    this.$axios.api.videoDetail({id: this.activeVideoId}, (res) => {
      if (res.code == 0) {
        this.title = res.data.course.title;
        this.course = res.data.course;
        this.video = res.data.video;
        this.isWatch = res.data.is_watch;
        this.isBuy = res.data.is_buy;
        this.courseCatelog = res.data.videos;
        this.playUrls = res.data.playUrls;
        this.chapters = res.data.chapters;
        this.courseIntro = res.data.course.render_desc;
        window.document.title += ' - ' + this.title;
        if (this.isWatch == false) {
          this.showBuy();
        } else {
          this.playerOptions.sources.push({
            type: 'video/mp4',
            src: this.playUrls[0].url
          })
        }
      } else {
        console.log(res.msg);
      }
    });
  },
  methods: {
    //播放
    toVideo(item){
      this.activeVideoId = item.id;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      // 播放权限校验
      this.$axios.api.videoDetail({id: this.activeVideoId}, (res) => {
        if (res.code == 0) {
          if (res.data.is_watch) {
            this.dialogBuyTips = false;
            //播放视频
            this.$axios.api.videoPlayUrl({id: this.activeVideoId}, (res) => {
              if (res.code == 0) {
                this.playUrls = res.data.playUrls;
                //加载当前视频
                this.$refs.videoPlayer.player.src(this.playUrls[0].url);
              } else {
                console.log(res.msg);
              }
            });
          } else {
            this.showBuy();
          }
        } else {
          console.log(res.msg);
        }
      });
    },
    //下载本节附件
    downloadThisAttach(){

    },
    //播放回调
    onPlayerPlay(player){
      if (this.isWatch == false) {
        this.showBuy();
        this.$refs.videoPlayer.player.pause();
      }
    },
    // 暂停回调
    onPlayerPause(player) {
      console.log(player);
    },
    // 视频播完回调
    onPlayerEnded(player) {
      console.log(player);
    },
    // 已开始播放回调
    onPlayerPlaying(player) {
      console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      console.log(player)
    },
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      player.play();
    },
    // 显示购买
    showBuy(){
      this.dialogBuyTips = true;
    },
    // 购买视频
    toBuyVideo(){
      window.open(process.env.VUE_APP_WWW_URL + 'dist/#/pay/videos/'+this.activeVideoId);
    },
    // 购买课程
    toBuyCourse(){
      window.open(process.env.VUE_APP_WWW_URL + 'dist/#/pay/courses/'+this.course.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  // 调整播放器样式
  .video-js .vjs-icon-placeholder {
    width: 100%;
    height: 100%;
    display: block;
  }
.breadcrumb{
  margin:20px 0;
}
.video-con{
  background-color:#fff;
  align-items:flex-start;
  .video-box{
    width: 900px;
    height: 520px;
    background:#000;
    position: relative;
    .video{
      width:100%;
      height:100%;
    }
    .video-player{
      ::v-deep .vjs-big-play-button{
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
      }
    }
    .tips-box{
      width:100%;
      height:100%;
      background:#000;
      position:absolute;
      left:0;
      top:0;
      z-index: 99;
      color:#fff;
      .text{
        font-size: 16px;
        margin-bottom: 50px;
      }
    }
  }
  .catelog-list{
    width:280px;
    color: #666;
    padding:10px;
    height:500px;
    overflow-y: auto;
    .item{
      cursor: pointer;
      padding:6px 0;
      max-width: 280px;
      @extend %textOverflow;
      i{
        color: #999;
        font-size: 14px;
      }
      &:hover{
        color: $colorMain;
        i{
          color: $colorMain;
        }
      }
      &.active{
        color: $colorMain;
        i{
          color: $colorMain;
        }
      }
    }
  }
}
.main-con{
  margin-top: 30px;
  align-items: flex-start;
  .main-con-l{
    width:895px;
    min-height: 500px;
    background-color: #fff;
    ::v-deep .el-tabs__header{
      .el-tabs__nav-wrap{
        padding:0 20px;
      }
      .el-tabs__item{
        font-size: 18px;
        height:52px;
        line-height: 52px;
      }
    }
    .catelog-list{
      padding:0 20px;
      .item{
        height: 52px;
        line-height: 52px;
        cursor: pointer;
        border-bottom: $borderDashed;
        position: relative;
        .duration{
          font-size: 14px;
          color: #999;
          position:absolute;
          right: 0;
        }
        .icon-play{
          font-size: 20px;
          margin-right: 10px;
          color:#666;
        }
        .attach{
          margin-left: 10px;
        }
        .title{
          max-width: 640px;
          @extend %textOverflow;
        }
        &:last-child{
          border: none;
        }
        &:hover{
          color:$colorMain;
          .icon-play{
            color:$colorMain;
          }
        }
        &.active{
          color:$colorMain;
          .icon-play{
            color:$colorMain;
          }
        }
      }
    }
    .courseIntro-con{
      padding:0 20px;
      ::v-deep img{
        max-width: 100%!important;
        height:auto!important;
      }
    }
  }
}
</style>
